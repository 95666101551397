
/* eslint-disable */
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Rating from "../Rating.vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
const listData = ref();

const instructor = ref('')
export default defineComponent({
  name: "voluntary-detail",
  components: {
    Rating,
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    onMounted(() => {
      //   , ['ข่าวสารและบทความ']
      
      getDataList();
    });
    const getDataList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getCourse?id=" +
            route.params.id +
            "&os=0",
          // "&categories=" +
          // categories.value
          {
            headers: {
              token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          // console.log(response);
          listData.value = response.data.data.content[0];
          // console.log(listData.value);
          renderInstructor(listData.value.instructor)
          setCurrentPageBreadcrumbs(listData.value.title, ["หลักสูตรทั่วไป"]);
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const renderInstructor = (uid) =>{
      // console.log("renderInstructor");
      if (uid) {
        axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getProfile?id=" + uid ,
          {
            headers: { token: localStorage.getItem("id_token")},
           
          }
        )
        .then((response) => {
          instructor.value = response.data.data.firstName + " " +  response.data.data.lastName
        })
        .catch((error) => {
        });
      }else{
        instructor.value = "-"
      }
    
    }
    const register = () => {
      if (localStorage.getItem("id_token")) {
        if (listData.value.register == false) {
          const formData = new FormData();
          formData.append("courseId", route.params.id + "");
          axios
            .post(process.env.VUE_APP_API_URL + "/registerCourse", formData, {
              headers: {
                token: localStorage.getItem("id_token")
              },
            })
            .then((res) => {
              if (res.data.code == 200) {
                Swal.fire({
                  title: "ลงทะเบียนสำเร็จ",
                  text: "รายการของคุณลงทะเบียนเรียบร้อยแล้ว",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "ตกลง!",
                  customClass: {
                    confirmButton: "btn fw-semobold btn-light-primary",
                  },
                }).then(function(res) {
                  router.push({
                    name: "voluntary-learn",
                    params: { id: route.params.id },
                  });
                });
              } else {
                  Swal.fire({
                title: "แจ้งเตือนข้อผิดพลาด",
                text: res.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
              }
            })
            .catch((error) => {
              // console.log(error);
            });
        } else {
          router.push({
            name: "voluntary-learn",
            params: { id: route.params.id },
          });
        }
      } else {
        router.push({ name: "sign-in" });
      }
    };
    const share = (social) => {
      const url = new URL(window.location.href);
      if (social == "facebook") {
        window.open(
          "http://facebook.com/sharer/sharer.php?u=" +
            encodeURIComponent(url.href),
          "",
          "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
        );
      } else if (social == "twitter") {
        window.open(
          "http://www.twitter.com/share?url=" + encodeURIComponent(url.href),
          "",
          "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
        );
      } else {
        window.open(
          "https://social-plugins.line.me/lineit/share?url=" +
            encodeURIComponent(url.href),
          "",
          "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
        );
      }
    };
    return {
      getDataList,
      listData,
      register,
      share,
      renderInstructor,
      instructor,

    };
  },
  methods: {
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
    rederRecommend(data) {
      // const dataMock = [
      //   "สำหรับประชาชนทั่วไป",
      //   "นักเรียนนักศึกษา",
      //   "คณะทำงานด้านเทคนิค",
      //   "เจ้าหน้าที่ปกครองส่วนท้องถิ่นวิศวกร",
      //   "ผู้พัฒนาโครงการ",
      // ];
      if (data.length > 0) {
        return data.join(", ");
      } else {
        return "-";
      }
    },
   
  },
});
